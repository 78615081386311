.menu-nav {
  display: flex;
  height: 80px;
  box-shadow: 0 -5px 5px -5px rgba(149, 157, 165, 0.2);
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  width: 100%;
}

.menu li {
  flex: 1;
  transition: background-position-x 0.9s linear;
  text-align: center;
}

.menu li.active a {
  color: #F84C0B;
}

.menu li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #777;
  text-decoration: none;
  transition: all 0.45s;
}

.menu li a span {
  font-size: 12px;
  font-weight: 400;
  margin-top: 3px;
}